.contact-wrapper {
  /* background-color: rgb(189, 200, 226); */
  /* background-color: rgb(224, 240, 255); */
  /* background-color: aliceblue; */
  /* background-color: rgb(149, 209, 204); */
  /* background-color: rgb(246, 242, 212); */
  /* background-color: rgb(4, 28, 50); */
  background-color: rgb(5, 45, 63);
  margin-top: 0px;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 0px;
}

.contact {
  padding-top: 5%;
  padding-bottom: 5%;
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  color: rgb(202, 202, 202);
}

.contact-title {
  color: white;
}

.contact-sep {
  /* background-color: rgb(4, 28, 50); */
  margin-top: 0px;
}

.contact-buttons {
  padding-top: 20px;
  /* padding-bottom: 20px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* max-height: 50px; */
  /* gap: 50px; */
}

.contact-icon {
  max-height: 50px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 50px;
  transition: 0.3s;
  filter: invert(1);
}

.contact-icon:hover {
  transform: scale(1.1);
}
